.archive{

    &__wrapper{
        margin: 0 0 30px 0;
    }

    &__project-wrapper{
        position: relative;
        transition: min-height 0.4s ease-in-out;
        min-height: 125px;
        &.active{
            min-height: calc(var(--app-height) - 130px);
        }
    }

    &__title-wrapper{
        display: inline-block;
        width: calc(100% - 141px);
        padding: 30px;
        border: var(--line-width) solid var(--line-color);
        border-bottom: none;
        border-radius: 30px 30px 30px 0;
        margin: 0 0 5px 30px;
        position: relative;
    }

    &__text{
        width: 70%;
        cursor: pointer;
        display: block;
        text-align: left;
        letter-spacing: -1px;
        @include hover{
            color: var(--hover-color);
        }
    }

    &__project-details{
        width: 50%;
        display: flex;
        flex-direction: row;
        margin-top: 5px;
    }

    &__project-detail{
        width: calc(33% - 10px);
        margin-right: 10px;
        border-radius: 30px;
        background-color: var(--archive-button);
        font-size: 14px;
        letter-spacing: 0;
        text-align: center;
        cursor: pointer;
        @include hover{
            color: var(--hover-color);
        }
        &.credit{
            background-color: transparent;
            text-align: left;
            cursor: auto;
            width: auto;
            font-size: 18px;
            letter-spacing: -1px;
            @include hover{
                color: inherit;
            }
        }
    }

    &__image-wrapper{
        display: block;
        height: calc(100% - 30px);
        width: 150px;
        background-color: #EEE;
        border-radius: 10px;
        position: absolute;
        top: 15px;
        right: 40px;
        transition: opacity 0.4s ease-in-out;
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
        &.disabled{
            opacity: 0;
            pointer-events: none;
        }
    }

    &__image{
        display: block;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__media-wrapper{
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease-in-out;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }



}

@include mobile{
    .archive{

        &__project-wrapper{
            &.active{
                min-height: calc(45vh - 100px);
            }
        }

        &__title-wrapper{
            width: calc(100% - 70px);
            padding: 15px;
            border-radius: 12px 12px 12px 0;
            margin: 5px 0 5px 20px;
        }

        &__text{
            width: 100%;
        }

        &__project-details{
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__project-detail{
            width: calc(50% - 10px);
            margin-right: 0;
            border-radius: 30px;
            margin-top: 3px;
            padding-top: 3px;
            &.credit{
                width: 100%;
                flex-grow: 1;
                display: block;
                
            }
        
        }

        &__image-wrapper{
            height:100px;
            width: auto;
            border-radius: 5px;
            position: static;
            margin: 5px auto 0 auto;
            transition: opacity 0.4s ease-in-out, height 0.4s ease-in-out;
            &.disabled{
                opacity: 0;
                height: 0;
                pointer-events: none;
            }
        }

        &__image{
            height: 100%;
            min-width: auto;
            min-height: auto;
            position: static;
            left:auto;
            top: auto;
            transform: none;
        }
    }
}