:root {
    --app-height: 100vh;
    --dom-bkg: #FFF;
    --line-color: #000;
    --line-color-blend: #FFF;
    --text-color: #000;
    --svg-fill: #000;

    --text-shadow: none;

    --jet-background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 90%,
        rgba(255, 255, 255, 0) 100%
      );
    --mix-blend: difference;
    --archive-button: #EEEEEE;
    --contact-bkg: #FFF;
    --hover-color: #00F;

    --line-width: 0.5px;
}