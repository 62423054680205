.spedo {
    &__container {
      width: calc(100% - 85px);
      min-height: var(--app-height);
      position: absolute;
      top: 0;
      left: 30px;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
    //   background-color: red;
    }

    &__border-box{
        width: 100%;
        height: 20vh;
        border-radius: 30px;
        border: var(--line-width) solid var(--line-color);
        border-bottom: none;
        position: absolute;
        top: 50px;
        left: 0;
    }

    &__wrapper{
        width: calc(100% - 30px);
        // width: 100%;
        margin: 0 0 0 0;
        mix-blend-mode: var(--mix-blend);
    }

    &__dials-wrapper{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        // border: var(--line-width) solid var(--line-color);
        position: relative;
    }

    &__dial-small{
        width:15%;
        aspect-ratio: 1/1;
        border: var(--line-width) solid var(--line-color-blend);
        position: absolute;
        bottom: 0;
        border-radius: 50%;
        &.fuel{
            left: 0;
        }
        &.temp{
            right: 0;
        }
    }

    &__small-hand{
        position: absolute;
        width: 15%;
        aspect-ratio: 1/1;
        border: var(--line-width) solid var(--line-color-blend);
        border-radius: 50%;
        left: calc(42.5% - 1px);
        bottom: 10%;
        transform-origin: center center;
        &::after{
            content: "";
            height: 10vw;
            border-left: 4px solid var(--line-color-blend);
            position: absolute;
            bottom: -0.5vw;
            left: calc(50% - 2px);
        }
    }

    &__small-marker-wrapper{
        position: absolute;
        width: 15%;
        aspect-ratio: 1/1;
        left: 42.5%;
        bottom: calc(10% + var(--line-width));
        transform-origin: center center;
    }

    &__small-marker{
        width: 9vw;
        height: 0;
        position: absolute;
        top: calc(50% - 2px);
        right: calc(50% - 2px);
        transform-origin: center right;
        &:after{
            content: "";
            width: 15%;
            border-top: 4px solid var(--line-color-blend);
            position: absolute;
            left: 0;
        }
    }

    &__dial-large{
        width: calc(35% - 30px);
        aspect-ratio: 1/1;
        margin: 0 15px;
        border: var(--line-width) solid var(--line-color-blend);
        border-radius: 50%;
        position: relative;
    }

    &__large-hand{
        position: absolute;
        width: 8%;
        aspect-ratio: 1/1;
        border: var(--line-width) solid var(--line-color-blend);
        border-radius: 50%;
        left: calc(46% - 1px);
        top: calc(46% - 1px);
        transform-origin: center center;
        &::after{
            content: "";
            width: 15vw;
            border-top: 4px solid var(--line-color-blend);
            position: absolute;
            top: calc(50% - 2px);
            right: -1vw;
        }
    }

    &__large-marker-wrapper{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        left: 0;
        top: 0;
    }

    &__large-marker{
        width: 49%;
        position: absolute;
        transform-origin: center right;

        &.large{
            top: calc(50% - 2px);
            right: calc(50% - 2px);
            transform: translate(-50% -50%);
            &:after{
                content: "";
                width: 15%;
                border-top: 4px solid var(--line-color-blend);
                position: absolute;
                left: 0;
            }
        }

        &.small{
            top: calc(50% - (var(--line-width) / 2));
            right: calc(50% - (var(--line-width) / 2));
            &:after{
                content: "";
                width: 15%;
                border-top: var(--line-width) solid var(--line-color-blend);
                position: absolute;
                left: 0;
            }
        }

    }


    &__buttons-wrapper{
        width: 100%;
        height: 30px;
        margin: 30px auto 0 auto;
        border-radius: 10px;
        border: var(--line-width) solid var(--line-color-blend);
        // pointer-events: all;
        // cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
    }

    &__button-wrapper{
        height: calc(100% - 10px);
        margin: 5px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: var(--line-width) solid var(--line-color-blend);
        border-radius: 5px;
        text-shadow: none !important;
        color: var(--line-color-blend);
        font-size: 12px;
        line-height: 0;
        &.small{
            width: 15%;
        }
        &.large{
            width: 30%;
        }
        &.center{
            width: 10%;
            pointer-events: all;
            cursor: pointer;
            div{
                width: 100%;
                height: 0px;
                pointer-events: none;
            }
        }

        &.refuel{
            position: absolute;
            bottom: calc(100% + 5px);
            left: 50%;
            height: 40px;
            margin: 0;
            transform: translateX(-50%);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s ease-in-out;
            &.active{
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &__secret-link{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 12px;
        border: var(--line-width) solid var(--line-color-blend);
        text-shadow: none !important;
        color: var(--line-color-blend);
        pointer-events: all;
        @include hover {
            color: var(--line-color-blend);
        }

        &.mode{
            top: 28px;
            padding: 5px 8px;
        }
    }

}

@include mobile {

    .spedo {
        &__container {
          width: calc(100% - 40px);
          min-height: 100%;
          margin: 0 20px;
          left: 0;
        }

        &__border-box{
            width: 100%;
            height: 15vh;
            border-radius: 10px;
        }

        &__wrapper{
            width: 100%;
            margin: 0;
        }

        &__small-hand{
            &::after{
                border-left: var(--line-width) solid var(--line-color-blend);
                left: calc(50% - 0.5px);
            }
        }

        &__small-marker-wrapper{
            display: none;
        }

        &__dial-large{
            width: calc(35% - 10px);
            margin: 0 5px;
        }

        &__large-hand{
            &::after{
                border-top: var(--line-width) solid var(--line-color-blend);
                top: calc(50% - 1px);
            }
        }

        &__large-marker-wrapper{
            display: none;
        }

        &__buttons-wrapper{
            margin-top: 5px;
        }

        &__button-wrapper{
            display: none;
        }

        &__secret-link{
            display: none;
        }
    }



}