.clients{

    &__wrapper{
        position: relative;
        background: rgb(255, 255, 255);
        background: var(--jet-background);
        transition: background-color 0.4s ease-in-out;
    }

    &__text{
        width: calc(100% - 6vw - 90px);
        margin: 0 calc(55px + 3vw) 30px calc(35px + 3vw);
        text-align: center;
        min-height: var(--app-height);
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        h3{
            margin-top: 20px;
        }
        a{
            text-decoration: underline;
        }
    }
}

@include mobile {
    .clients{
        &__wrapper{
            margin: 10vh auto;
        }
    
        &__text{
            width: calc(100% - 40px);
            margin: 0 20px;
            min-height: 60vh;
            height: auto;
            h3{
                margin-top: 10px;
            }
        }
    }

}