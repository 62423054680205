@font-face {
  font-family: "Social";
  src: url("../assets/fonts/ABCSocialCondensed-Black.eot");
  src: url("../assets/fonts/ABCSocialCondensed-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/ABCSocialCondensed-Black.woff2") format("woff2"),
    url("../assets/fonts/ABCSocialCondensed-Black.woff") format("woff"),
    url("../assets/fonts/ABCSocialCondensed-Black.ttf") format("truetype"),
    url("../assets/fonts/ABCSocialCondensed-Black.svg#ABCSocialCondensed-Black")
      format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cond";
  src: url("../assets/fonts/Cond.eot");
  src: url("../assets/fonts/Cond.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Cond.woff2") format("woff2"),
    url("../assets/fonts/Cond.woff") format("woff"),
    url("../assets/fonts/Cond.ttf") format("truetype"),
    url("../assets/fonts/Cond.svg#Cond") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: "Social", "Times", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  @include hover {
    text-decoration: none;
    color: var(--hover-color);
  }
}

h1 {
  font-size: 6vw;
  line-height: 5.4vw;
  letter-spacing: -0.2vw;
  strong {
    font-size: 4vw;
    line-height: 4vw;
  }
}
h2 {
  font-size: 4vw;
  line-height: 4vw;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

.alt-font {
  font-family: "Cond", serif;
}

.small-text {
  font-size: 30px;
  line-height: 20px;
}
.small-caps {
  font-size: 4.5vw;
  line-height: 5vw;
  text-transform: uppercase;
}

.archive-small-caps {
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
}

.large-text {
  font-size: 6vw;
  line-height: 5vw;
  letter-spacing: -0.2vw;
}

@include mobile {
  html,
  body {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -1px;
  }

  .large-text {
    font-size: 40px;
    line-height: 30px;
    letter-spacing: -1px;
  }

  .small-caps {
    font-size: 30px;
    line-height: 30px;
    text-transform: uppercase;
  }

  h1 {
    font-size: 40px;
    line-height: 35px;
    letter-spacing: -1px;
    strong {
      font-size: 30px;
      line-height: 30px;
      letter-spacing: -1px;
    }
  }
}
