.header{

    &__piss-wrapper{
        position: fixed;
        top: 10px;
        left: 20px;
        height: 60px;
        width: 60px;
        z-index: 100;
        display: block;
        img{
            display: block;
            height: 40px;
            width: auto;
        }
    }

    &__type-container{
        position: fixed;
        top: 10px;
        // left: 50%;
        // transform: translateX(-50%);
        // height: 35px;
        width: calc(100% - 55px);
        z-index: 100;
        pointer-events: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__type-wrapper{
        height: 35px;
        width: 120px;
        pointer-events: all;
    }
    &__type-logo{
        display: block;
        height: 100%;
        width: auto;
        // pointer-events: all;
    }

    &__info{
        position: fixed;
        top: 10px;
        right: 40px;
        width: auto;
        display: block;
        z-index: 100;
        color: var(--text-color);
    }
}

@include mobile{

    .header{

        &__piss-wrapper{
            left: 10px;
            height: 50px;
            width: 50px;
            img{
                height: 30px;
            }
        }
        &__type-container{
            width: 100%;
        }

        &__type-wrapper{
            height: 24px;
            width: 90px;
            padding: 7px 20px 5px 20px;
        }

        &__info{
            right: 10px;
            padding: 10px 12px 7px 12px;
        }
    }
}
