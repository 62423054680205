.latest {
  &__wrapper {
    height: calc(var(--app-height) - 100px);
    position: relative;
  }

  &__title {
    margin: 0 30px;
  }


  // &__buttons-wrapper{
  //   display: block;
  //   position: absolute;
  //   top: calc(100% - 0.5vw);
  //   display: flex;
  //   justify-content: center;
  //   z-index: 3;
  // }

  &__launch-button{
    display: inline-block;
    padding: 0 50px;
    position: relative;
    top: -20px;
    border-radius: 30px;
    background-color: var(--archive-button);
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
    margin-left: 20px;
    @include hover{
        color: var(--hover-color);
    }

    &.collaborator{
      padding: 0;
      font-size: 18px;
      margin-right: 20px;
      background-color: transparent;
      text-align: left;
    }
  }

  &__launch-mobile{
    display: none;
  }
}

@include mobile {
  .latest {
    &__title {
      margin: 0 20px;
    }

    &__wrapper {
      height: 45vh;
    }
    &__latest{
        display: block;

    }


    &__buttons-wrapper{
      top: calc(100% - 10px);
      display: flex;
      justify-content: center;
      width: calc(100% - 40px);
      z-index: 3;
    }

    &__launch-button{
      width: 100%;
      &.launch{
        display: none;
      }
    }

    &__launch-mobile{
      display: inline-block;
      padding: 0 50px;
      position: relative;
      top: -20px;
      border-radius: 30px;
      background-color: var(--archive-button);
      font-size: 14px;
      letter-spacing: 0;
      margin-left: 20px;
      width: calc(100% - 140px);
      text-align: center;
      cursor: pointer;
    }
  }
}
