.background{

    &__wrapper{
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 0;
        height: 0;
        overflow-y: visible;
        opacity: 1;
        transition: opacity 0.4s ease-in-out;
        backdrop-filter: opacity(1);
        &.disabled{
            opacity: 0;
        }
    }

    &__logo-wrapper{
        display: block;
        height: var(--app-height);
        aspect-ratio: 1/1;
        // width: 100%;
        max-width: calc(100% - 100px);
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__image{
        display: block;
        width: 100%;
    }
    
}

@include mobile{
    .background{

        &__wrapper{
            display: none;
        }
        
    }
}