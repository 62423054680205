.selected{

    &__project-wrapper{
        position: relative;
        // height: calc(var(--app-height) - 100px);
    }

    &__project-title{
        // margin: 0 55px 30px 30px;
        // padding: 30px 30px 30px 30px;
        margin: 0 55px 0 30px;
        padding: 30px 30px 0 30px;
        border: var(--line-width) solid var(--line-color);
        border-bottom: none;
        border-radius: 30px 30px 30px 0;
        display: flex;
        flex-direction: row;
    }


    &__buttons{
        flex-grow: 1;
        margin-left: 10px;
        width: 180px;
        white-space: nowrap;
    }

    &__launch-button{
        display: inline-block;
        padding: 0 50px;
        border-radius: 30px;
        background-color: var(--archive-button);
        font-size: 14px;
        letter-spacing: 0;
        text-align: center;
        cursor: pointer;
        @include hover{
            color: var(--hover-color);
        }
      }
}

@include mobile{
    .selected{

        &__project-title{
            display: block;
            flex-direction: row;
        }

        &__project-wrapper{
            position: relative;
            // height: 45vh;
            height: auto;
        }
        &__project-title{
            margin: 5px 20px 0 20px;
            padding: 15px 15px 0 15px;
            border: var(--line-width) solid var(--line-color);
            border-bottom: none;
            border-radius: 12px 12px 12px 0;
        }
        &__buttons{
            margin-left: 0;
            width: 100%;
            margin-top: 5px;
        }
        &__launch-button{
            display: block;
            margin: 0 auto;
          }
    }

}