.scrollbar {
  &__wrapper {
    height: calc(var(--app-height) - 40px);
    width: 20px;
    margin-right: 15px;
    position: fixed;
    right: 0;
    top: 20px;
    display: block;
    z-index: 100;
    display: flex;
    flex-direction: column;
  }

  &__inner-wrapper {
    flex-grow: 1;
    width: 12px;
    padding: 4px;
    position: relative;
  }

  &__mark {
    position: absolute;
    width: 19px;
    height: 15px;
    border-left: 0.5px solid var(--line-color);
    border-right: 0.5px solid var(--line-color);
    left: 0;
    // z-index: 1;
    &.top {
      top: 0;
      border-top: 0.5px solid var(--line-color);
      border-radius: 3px;
    }
    &.seventy-five {
      top: 25%;
    }
    &.fifty {
      top: 50%;
    }
    &.twenty-five {
      top: 75%;
      border-color: #dc4242;
    }
    &.bottom {
      bottom: 0;
      height: 15px;
      border-bottom: 0.5px solid ;
      border-color: #dc4242;
      border-radius: 3px;
    }

    &.seventy-five::after,
    &.fifty::after,
    &.twenty-five::after {
      content: "";
      width: 100%;
      height: 1px;
      border-top: 0.5px solid var(--line-color);
      position: absolute;
      top: 6px;
    }
    &.twenty-five::after{
        border-color: #dc4242;
    }
  }

  &__level-wrapper{
    width: calc(100% - 8px);
    z-index: 2;
    bottom: 4px;
    background-color: var(--line-color);
    position: absolute;
    border-radius: 3px;
    min-height: 8px !important;
    &.low{
        background-color: #dc4242 !important;
        animation: pulse 1s infinite linear;
    }
  }

  &__icon-wrapper {
    font-size: 12px;
    line-height: 10px;
    text-align: center;
    white-space: nowrap;
    width: 100%;
  }

  &__icon{
    width: 100%;
    height: auto;
    margin: 4px 2px;
    display: block;
  }

  &__text-wrapper{
    width: 40px;
    position: relative;
    left: -10px;
    text-align: center;
  }
}

@include mobile{
  .scrollbar {
    &__wrapper {
      display: none;
    }
  }
}