.media {
  &__outer-wrapper {
    height: calc(var(--app-height) - 85px - 9vw);
    // position: absolute;
    // bottom: 0;
    position: relative;
    top: -30px;
    width: calc(100% - 20vh);
    padding: 0 10vh;
    overflow-x: auto;
    &.selected{
      height: calc(var(--app-height) - 115px - 9vw);
    }
  }

  &__inner-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__spacer {
    height: 100%;
    width: 10vh;
    flex-shrink: 0;
  }

  &__media-wrapper {
    height: calc(100% - 60px);
    // width: 100%;
    border-radius: 10px;
    margin: 15px 30px 15px 0;
    -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
    // flex-shrink: 0;
  }

  &__desktop-bar {
    width: 100%;
    height: 25px;
    background-color: #595959;
    border-radius: 10px 10px 0 0;
    position: relative;
  }

  &__desktop-buttons-wrapper {
    position: absolute;
    top: -5px;
    left: 5px;
  }

  &__desktop-button {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 5px 0 0 5px;
    &.red {
      background-color: #dc4242;
    }
    &.yellow {
      background-color: #fcc700;
    }
    &.green {
      background-color: #13bf04;
    }
  }

  &__desktop-video-wrapper {
    display: block;
    background-color: #EEE;
    height: calc(100% - 25px);
    width: auto;
    border-radius: 0 0 10px 10px;
  }

  &__desktop-image-wrapper {
    display: block;
    height: calc(100% - 25px);
    width: auto;
    border-radius: 0 0 10px 10px;
    background-color: #EEE;
  }

  &__regular-video-wrapper {
    display: block;
    background-color: #EEE;
    height: 100%;
    width: auto;
    border-radius: 10px;
  }

  &__regular-image-wrapper {
    display: block;
    height: 100%;
    width: auto;
    border-radius: 10px;
    background-color: #EEE;
  }

  &__mobile-video-wrapper {
    display: block;
    background-color: #EEE;
    height: calc(100% - 50px);
    width: auto;
    border-radius: 0 0 10px 10px;
  }

  &__mobile-image-wrapper {
    display: block;
    height: calc(100% - 50px);
    width: auto;
    border-radius: 0 0 10px 10px;
    background-color: #EEE;
  }

  &__mobile-bar{
    height: 50px;
    width: 100%;
    background-color: #595959;
    border-radius: 10px 10px 0 0 ;
  }

  &__mobile-bar-top{
    height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__mobile-bar-top-item{
    text-align: center;
    color: #CCC;
    font-size: 12px;
    &.middle{
        height: 100%;
        width: 33%;
        background-color: #333;
        border-radius: 0 0 10px 10px;
    }
  }

  &__mobile-battery-wrapper{
    width: 25px;
    height: 8px;
    border: 1.5px solid #CCC;
    margin-top: 8px;
    border-radius: 2px;
    position: relative;
    &::after{
        content: "";
        width: 4px;
        height: 4px;
        background-color: #CCC;
        position: absolute;
        left: 100%;
        top: 2px;
    }
  }

  &__mobile-battery-charge{
    margin: 1.5px;
    width: 5px;
    height: calc(100% - 3px);
    background-color: #dc4242;
  }

  &__mobile-bar-bottom{
    height: 20px;
    width: calc(100% - 20px);
    margin: 5px 10px;
    background-color: #333;
    border-radius: 20px;
    color: #CCC;
    font-size: 12px;
    line-height: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
  }
}

@include mobile {

  .media {
    &__outer-wrapper {
      position: static;
      height:35vh;
      width: calc(100% - 40px);
      padding: 0 20px;
      &.selected{
        height: calc(35vh - 40px);
      }
    }

    &__spacer {
      width: 20px;
    }

    &__media-wrapper {
      height: calc(100% - 50px);
      border-radius: 5px;
      margin: 15px 10px 15px 0;
    }

    &__desktop-video-wrapper {
      height: calc(100% - 10px);
      border-radius: 0 0 5px 5px;
    }
    &__desktop-image-wrapper {
      height: calc(100% - 10px);
      border-radius: 0 0 5px 5px;
    }


    &__desktop-bar {
      height: 10px;
      border-radius: 5px 5px 0 0;
    }
  
    &__desktop-buttons-wrapper {
      top: -8px;
      left: 5px;
    }
  
    &__desktop-button {
      height: 5px;
      width: 5px;
      margin: 0 3px 0 1px;
    }

    &__regular-video-wrapper {
      border-radius: 5px;
    }
  
    &__regular-image-wrapper {
      border-radius: 5px;
    }


    &__mobile-bar{
      height: 10px;
      border-radius: 5px 5px 0 0 ;
      margin: 0;
      padding-top: 1px;
    }
    &__mobile-bar-top{
      display: none;
    }

    &__mobile-bar-bottom{
      height: 8px;
      width: calc(100% - 20px);
      margin: 1px 10px;
      color: #CCC;
      font-size: 5px;
      line-height: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-transform: uppercase;
    }

    &__mobile-video-wrapper {
      height: calc(100% - 10px);
      border-radius: 0 0 5px 5px;
    }
  
    &__mobile-image-wrapper {
      height: calc(100% - 10px);
      border-radius: 0 0 5px 5px;
    }
  }
}