.splash{

    &__wrapper{
        width: 100%;
        margin: 0 auto;
        height: var(--app-height);
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 1;
    }

    &__logo-wrapper{
        display: block;
        height: 100%;
        aspect-ratio: 1/1;
        max-width: calc(100% - 100px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__logo{
        display: block;
    }

    &__rush{
        display: block;
        margin: 0 0 0 50%;
        height: 30vh;
        width: 30vh;
        transform-origin: center center;
        &.bottom{
            transform: rotate(180deg);
            margin: 0 0 0 20%;
        }
    }

    &__incense{
        text-shadow: none !important;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease-in-out;
        position: absolute;
        top: 60vh;
        right: 28.7vw;
        color: #dc4242;
        &.active{
            opacity: 1;
        }
    }
}

@include mobile {

    .splash{

        &__wrapper{
            height: 60vh;
        }
    }
}