.jet {
  &__container {
    width: calc(100% - 55px);
    height: var(--app-height);
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    clip: rect(0, auto, auto, 0);
    // z-index: 2;s
    pointer-events: none;
  }

  //SIDEBARS
  &__sidebar-container {
    position: absolute;
    height: 100%;
    width: 3vw;
    top: 0;
    overflow: hidden;
    &.left {
      left: 30px;
    }
    &.right {
      right: 0;
    }
  }
  &__sidebar-wrapper {
    position: fixed;
    height: var(--app-height);
    width: 3vw;
    top: 0;
    overflow: hidden;
  }

  &__sidebar-line {
    border-top: var(--line-width) solid var(--line-color);
    height: 1vh;

    &.mid {
      width: 75%;
      &.right {
        margin-left: 25%;
      }
    }

    &.small {
      width: 50%;
      &.right {
        margin-left: 50%;
      }
    }
  }

  &__sidebar-gradient {
    position: absolute;
    width: 3vw;
    height: 5vh;
    z-index: 2;

    &.top {
      top: 0;
      left: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &.bottom {
      bottom: 0;
      left: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  //CENTER
  &__center-container {
    width: 35%;
    height: 60%;
    position: absolute;
    top: 20%;
    left: 32.5%;
    border-left: var(--line-width) solid var(--line-color-blend);
    border-right: var(--line-width) solid var(--line-color-blend);
    mix-blend-mode: var(--mix-blend);
    // backdrop-filter: opacity(1);
    // -webkit-backdrop-filter: opacity(1);
    // -webkit-backdrop-filter: blur(5px);
    // backdrop-filter: opacity(1);
    z-index: 10;
  }

  &__center-crop-mark {
    height: 100%;
    width: 20px;
    border-top: var(--line-width) solid var(--line-color-blend);
    border-bottom: var(--line-width) solid var(--line-color-blend);
    position: absolute;
    top: 0;
    &.left {
      right: 100%;
    }
    &.right {
      left: 100%;
    }
  }

  &__center-crop-alt-mark{
    height: 20%;
    width: 70%;
    border-bottom: var(--line-width) solid var(--line-color-blend);
    &.right{
        margin-left: 30%;
    }
  }

  &__crop-button {
    width: 80px;
    height: 20px;
    border: var(--line-width) solid var(--line-color-blend);
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--line-color-blend);
    font-size: 12px;
    line-height: 0;
    pointer-events: all;
    text-shadow: none;
    cursor: pointer;
    button{
      display: block;
      height: 20px;
    }
    &.top {
      top: -10.5px;
    }
    &.bottom {
      bottom: -10.5px;
    }
    &.left {
      right: 30px;
      border-right: none;
      &::before {
        content: "";
        width: 14.5px;
        height: 14.5px;
        border-top: var(--line-width) solid var(--line-color-blend);
        border-right: var(--line-width) solid var(--line-color-blend);
        transform-origin: center center;
        transform: rotate(45deg);
        position: absolute;
        right: -8px;
      }
      a{
        @include hover {
          color: inherit;
        }
      }
    }
    &.right {
      left: 30px;
      border-left: none;
      &::before {
        content: "";
        width: 14.5px;
        height: 14.5px;
        border-top: var(--line-width) solid var(--line-color-blend);
        border-left: var(--line-width) solid var(--line-color-blend);
        transform-origin: center center;
        transform: rotate(-45deg);
        position: absolute;
        left: -8px;
      }
    }
  }

  &__center-wrapper {
    width: calc(100% - 60px);
    height: 100%;
    position: absolute;
    margin: 0 30px;
    overflow: hidden;

    &.circle {
      overflow: visible;
    }
  }

  &__center-circle {
    width: 30px;
    height: 30px;
    border: var(--line-width) solid var(--line-color-blend);
    position: absolute;
    border-radius: 50%;
    left: calc(50% - 15px);
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      border-top: var(--line-width) solid var(--line-color-blend);
      top: 14.5px;
      left: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      width: 15px;
      border-top: var(--line-width) solid var(--line-color-blend);
      top: 14.5px;
      right: 100%;
    }
  }
  &__center-circle-crop {
    width: 14.5px;
    height: 15px;
    border-right: var(--line-width) solid var(--line-color-blend);
    position: absolute;
    top: -15px;
    left: 0;
  }

  &__center-circle-dot {
    width: 5px;
    height: 5px;
    background-color: var(--line-color-blend);
    position: absolute;
    top: 35px;
    left: 12.5px;
    border-radius: 50%;
  }

  &__center-wrapper-altitude {
    position: absolute;
    height: 100vh;
    width: calc(100% - 60px);
    transform-origin: center center;
    left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__alt-marker-center {
    height: 15vh;
    width: 100%;
    position: relative;
  }

  &__alt-marker-center-marker {
    width: calc(50% - 40px);
    border-top: var(--line-width) solid var(--line-color-blend);
    position: absolute;
    top: 50%;
    &.left {
      left: 0;
      &::before{
        content: "";
        height: 30px;
        width: 15px;
        position: absolute;
        left: 0;
        top: -15px;
        border-right: var(--line-width) solid var(--line-color-blend);
      }
      &::after{
        content: "";
        height: 15px;
        width: 15px;
        position: absolute;
        right: 0;
        top: -7px;
        border-left: var(--line-width) solid var(--line-color-blend);
      }
    }
    &.right {
      right: 0;
      &::before{
        content: "";
        height: 30px;
        width: 15px;
        position: absolute;
        right: 0;
        top: -15px;
        border-left: var(--line-width) solid var(--line-color-blend);
      }
      &::after{
        content: "";
        height: 15px;
        width: 15px;
        position: absolute;
        left: 0;
        top: -7px;
        border-right: var(--line-width) solid var(--line-color-blend);
      }
    }
  }

  &__alt-marker {
    width: calc(90% - 100px);
    height: 25vh;
    flex-grow: 1;
    padding: 0 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }

  &__alt-marker-small {
    width: 25%;
    height: 50%;
    border-bottom: var(--line-width) solid var(--line-color-blend);
  }
  &__alt-marker-large {
    width: calc(50% - 10px);
    margin: 0 15px;
    height: 50%;
    border-bottom: var(--line-width) solid var(--line-color-blend);
  }

  &__alt-marker-angle {
    width: 30px;
    position: absolute;
    border-bottom: var(--line-width) solid var(--line-color-blend);
    height: 50%;
    &.left {
      left: 0;
      transform-origin: right bottom;
      &.plus {
        transform: rotate(30deg);
      }
      &.minus {
        transform: rotate(-30deg);
      }
    }
    &.right {
      right: 0;
      transform-origin: left bottom;
      &.plus {
        transform: rotate(-30deg);
      }
      &.minus {
        transform: rotate(30deg);
      }
    }
  }
}


@include mobile{

  .jet {
    &__container {
      width: 100%;
    }

    &__sidebar-container {
      width: 15px;
      &.left {
        left: 10px;
      }
      &.right {
        right: 10px;
      }
    }
    &__sidebar-wrapper {
      height: 100%;
      width: 15px;
    }

    &__sidebar-gradient {
      width: 15px;
    }

    &__center-container {
      display: none;
    }
  }
}