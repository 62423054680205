.bio {
  &__wrapper {
    width: 100%;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }

  &__large-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin: 0 5%;
  }

  &__sub-text-wrapper {
    width: calc(100% - 100px);
    margin: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__sub-text {
    width: calc(50% - 30px);
    margin-right: 30px;
    p{
      margin-bottom: 10px;
    }
  }

  &__disclaimer{
    margin-top: 50px;
    font-size: 12px;
    line-height: 6px;
    letter-spacing: normal;
  }
}

@include mobile {
  .bio {
    &__wrapper {
      width: 100%;
      height: auto;
      margin: 10vh auto;
    }

    &__large-text {
        width: calc(100% - 40px);
        margin: 0 20px;
      }

      &__sub-text-wrapper {
        width: 100%;
        margin: 0;
        display: block;
      }

      &__sub-text {
        width: 100%;
        margin: 20px 0;
      }
  }
}
