*::selection {
  background: #000;
  color: #fff;
}
*::-moz-selection {
  background: #000;
  color: #fff;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 0;
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: #000;
  display: none;
}

*::-webkit-scrollbar-thumb {
  background: #fff;
  display: none;
  border: none;
}

html,
body {
  width: 100%;
  height: 100%;
  color: var(--text-color);
  text-shadow: var(--text-shadow);
  transition: background-color 0.4s ease-in-out;
  background-color: var(--dom-bkg);
}

.sky-background {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(197, 221, 233);
  background: linear-gradient(
    0deg,
    rgba(197, 221, 233, 1) 0%,
    rgba(197, 221, 233, 1) 24%,
    rgba(2, 149, 186, 1) 54%,
    rgba(1, 41, 78, 1) 77%,
    rgba(3, 20, 32, 1) 92%,
    rgba(0, 0, 0, 1) 100%
  );
  width: 100%;
  height: var(--app-height);
  z-index: -1;
  &.active{
    opacity: 1;
  }
}

.default-button {
  background-color: #fff;
  padding: 8px;
}

.portable-text {
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  a {
    text-decoration: underline;
  }
}

.header-blur {
  padding: 10px 20px 5px 20px;
  border-radius: 10px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  display: inline-block;
  &.piss {
    padding: 10px;
  }
}

.wrapper {
  height: 100vh;
}
.section {
  &__title {
    width: 50%;
    position: sticky;
    top: 10px;
    text-align: center;
    z-index: 100;
    pointer-events: none;
    &.padding {
      padding-left: 30px;
      width: calc(50% - 30px);
    }
  }

  &__border-box {
    width: calc(100% - 81px);
    border: var(--line-width) solid var(--line-color);
    border-bottom: none;
    border-radius: 30px 30px 30px 0;
    height: 40vh;
    margin: 0 30px;
    position: absolute;
    top: 50px;
    pointer-events: none;
  }
}


@include mobile{
  .section {
    &__title {
      width: 100%;
      top: 50px;
      &.padding {
        padding-left: 0;
        width: 100%;
      }
    }
  
    // &__border-box {
      // width: calc(100% - 81px);
      // border: var(--line-width) solid var(--line-color);
      // border-bottom: none;
      // border-radius: 30px 30px 30px 0;
      // height: 40vh;
      // margin: 0 30px;
      // position: absolute;
      // top: 50px;
      // pointer-events: none;
    // }
  }
}