.contact {
  &__content-wrapper {
    width: calc(100% - 21px);
    height: calc(var(--app-height) - 70px);
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgb(255, 255, 255);
    background: var(--jet-background);
  }

  &__large-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 30px;
    padding: 30px;
    border: var(--line-width) solid var(--line-color);
    border-radius: 30px;
    border-bottom: none;
    font-size: 7vw;
    letter-spacing: -0.3vw;
  }

  &__sub-text-wrapper {
    width: calc(100% - 60px);
    margin: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  &__sub-text {
    width: calc(50% - 92px);
    // margin-right: 30px;
  }

  &__address {
    margin-top: 20px;
  }

  &__border {
    border: var(--line-width) solid var(--line-color);
    border-radius: 30px;
    padding: 30px;
  }

  &__list {
    list-style: inside;
  }

  &__copy {
    padding-bottom: 30px;
    padding-right: 50px;
    text-align: center;
    background-color: var(--dom-bkg);
    color: var(--line-color);
    position: relative;
    z-index: 2;
  }

  &__social{
    margin-right: 15px;
  }
}

@include mobile {

  .contact {
    &__content-wrapper {
      width: 100%;
      height: auto;
    }

    &__large-text {
      margin: 5px 20px 0 20px;
      padding: 15px;
      height: 10vh;
      border-radius: 12px;
    }


    &__sub-text-wrapper {
      width: calc(100% - 40px);
      margin: 20px;
      display: block;
    }

    &__sub-text {
      width: calc(100% - 30px);
      margin-bottom: 20px;
    }

    &__border {
      border-radius: 12px;
      padding: 15px;
    }

    &__copy {
      padding-right: 0;
    }
  }
}